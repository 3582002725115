<template>
  <div class="view-ten-bast-battle">
    <CompHeader title="最佳对战">
      <div class="comp-header-right-box">
        <div class="coin"></div>
      </div>
    </CompHeader>
    <div class="ten-battle">
      <!-- <div v-for="item in list" :key="item.id" class="item"></div> -->
      <div
        @click="roomItemOnClick(item)"
        v-for="item in finalBattleList"
        :key="item.id"
        class="battle-main-list-box-item">
        <BattleItem :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import { getBattleTenBaseListApi } from "@/network/api.js";
import BattleItem from "./components/BattleItem.vue";
export default {
  components: { BattleItem },
  name: "tenBasetBattle",
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    finalBattleList() {
      return (this.list || []).map((wrapItem) => {
        let newSeatList = new Array(wrapItem.user_num).fill(null);
        newSeatList = newSeatList.map((wItem, index) => {
          let seatItem = wItem;
          wrapItem.game_arena_player.forEach((vItem) => {
            if (vItem.seat === index) {
              seatItem = vItem;
            }
          });
          return seatItem;
        });
        return {
          ...wrapItem,
          newSeatList,
          // status: 0,
        };
      });
    },
  },
  methods: {
    init() {
      console.log("init");
      this.getListFun();
    },
    roomItemOnClick(item) {
      // this.$message.warning("模块充能中！敬请期待");
      // return false;
      this.$router.push({
        path: "/battleRoom",
        query: {
          id: item.id,
        },
      });
    },
    async getListFun() {
      try {
        const params = {
          page: 1,
        };
        const res = await getBattleTenBaseListApi(params);
        console.log("对战十佳", res);
        if (res?.data?.code === 200) {
          this.list = res?.data?.data;
        } else {
          this.$message.error(res?.data?.message);
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.view-ten-bast-battle {
  min-height: 100vh;
  padding: 0.5rem 0rem 0.9rem;
  position: relative;
  // &::after {
  //   content: "";
  //   width: 100vw;
  //   height: 100vh;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: -99;
  //   @include bgImg("battle-bg");
  // }
  .ten-battle {
    padding: 0.12rem 0.2rem 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    .item {
      width: 1.6rem;
      height: 2.05rem;
      margin-bottom: 0.15rem;
      background: #ccc;
    }
  }
}
</style>
